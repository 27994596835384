@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,500;1,100;1,200;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown .dropdown-menu {
    
  -webkit-transition: all 600ms ease;
    
  transition: all 600ms ease;
  -moz-transition: all 200ms ease;
  -webkit-transition: all 0.0010ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -o-transition: all 0.001ms ease;
  -ms-transition: all 2000ms ease;
}


.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: -0.25rem 0 0 !important;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none !important;
  border-radius: .25rem;
}

.multitab-row{
    margin-top:30px;
    margin-left:60px;
    margin-bottom:60px;
}
.dropdown-content {
  width: 60px;
  padding: 5px 0px;
  display: none;
  border: none;
  box-shadow: 0px 0px 11px 1px #efefef;
}




.dropdown:hover .dropdown-menu {
  width:50px;
  display: block;
  margin-top:-10px;
}
.dropdown-item {
  padding: 5px 8px !important;
  list-style:none;
  margin-left:-5px;
  font-family:raleway,'sans-serif';
  color:rgb(97, 97, 97);
  font-size: 15px;
  font-family: 'Montserrat';

}

.dropdown-item:hover {
    text-decoration:none;
  color: rgb(2, 56, 87) !important;
}

.subText{
  font-size:15px;
  margin-left:15px;
  
}.sidebar {
  background-color: #1E5B81;
  height: calc(100vh - 90px);
  margin-top: 0px;
  padding: 10px 0px;
  width: 260px;
  box-shadow: 0px 1px 11px 0px #e9e6e6;
}
#sidebar1{
  display:block;
}
.sidebar-elementhead{
  font-size:18px;
  font-weight:700;
  padding:10px;
  
  font-family:Montserrat,'sans-serif';
  color:#ffffff !important;
  text-align:left;
  padding-right:40px;
  border-bottom:1px solid rgb(241, 241, 241);

}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size:16px;
  line-height: 1.5;
  border-radius: 0.15rem;
  -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.nav-link:hover .dropdown-menu{
  display:block;
}
.nav-item{
  font: size 19px !important;;
  font-family:montserrat !important;
  font-weight:600;
  margin: 10px -6px;
  color:rgb(75, 75, 75) !important;
}

.email-submit-button{
  margin-left:-37px !important; 
  font-size: 18px !important;
  border-radius: 0.15rem !important;
}

.detail-fields {
  font-family: Montserrat,'sans-serif';
  font-weight: 600;
  font-size: 14px !important;
  margin-top: 0px;
  color: rgb(56, 56, 56);
  background-color: #e8e9e930;
  /* border: 1px solid; */
  border: 1px solid #e5efef;
  padding: 8px;
  text-align: center;
}

.sidebar-element{
  margin:10px 0px -2px 0px;
  padding:14px 10px;
  font-size:14px;
  text-align:left;
  font-family:Montserrat,'sans-serif';
  color:rgb(255, 255, 255);
font-weight:700;

}
.sidebar-subelement{
  
  padding:2px 10px 2px 46px;
  font-size:14px;
  text-align:left;
  font-family:Montserrat,'sans-serif';
  color:rgb(255, 255, 255);
font-weight:500;
display:none;


}
.sidebar-subelement:hover{
  
 cursor:pointer;


}

.sub-tabs{
  padding:10px;
  background-color:rgb(250, 250, 250);
  
  border-radius:4px;
}

.sub-tabs:hover{
  padding:10px;
  background-color:rgb(255, 255, 255);
  
  border-radius:4px;
}
.sidebar-element:hover{
  margin:10px 0px -2px 0px;
  padding:14px 10px;
  font-size:14px;
  text-align:left;
  background-color: rgb(255, 255, 255);
 border-radius:0px;
 font-family:Montserrat,'sans-serif';
  color:rgb(26, 26, 26);
  cursor:pointer;
}
.sidebar-sectionName{
  opacity:0.8;
  font-size:18px;
  padding:8px;
  text-align:left;
  font-weight:700;
  color:grey;
  font-family:Montserrat,'sans-serif';
}

.oneTab{
  margin:5px 0px;
}
.fa{
  padding:0px 6px;
}
.right{
  text-align:right;
}

.navbar-brand{
  padding:10px 10px 0px 10px;

  height:57px;
}
.subtext{
  color:#307AB2;
  font-size:17px;
  font-weight:500;
  font-family:montserrat,'sans-serif';
  margin-top:-5px;
  margin-left:18px;
}
.navbar {
  background-color:#ffffff;
  height: 90px;
  /* box-shadow: 0px -5px 10px #fdfdfd; */
  z-index: 9999;
  width: auto;
  box-shadow: 5px 4px 9px #ebe9e947;
  margin-bottom: 0px !important;
  /* border-bottom: 1px solid rgb(241, 241, 241); */
}
.navbar-nav>li>a {
  padding-top: 26px;
  padding-bottom: 22px;
  
}

.navbar-nav .dropdown-menu {
  position: absolute !important;
  float: none;
  padding-right:30px;
  margin-left:-100px !important;
}

.mainDiv{
  
   background-color: rgb(241, 241, 241);
  overflow-y:none;
  overflow-x:scroll;
}

.tabImage{
  width:140px;
}
.tab-row{
  margin-top:-20px;
}
.tab-text{
  font-family:Montserrat,'sans-serif';
  font-weight:600;
  font-size:14px;
}


/*form - css*/

.form-text{
  
  font-size:13px;
  font-family: Montserrat,'sans-serif';
  font-weight:500;
  
}


.table>tbody>tr>td, .table>tfoot>tr>td {
  padding: 4px 4px 4px 11px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid rgb(236, 236, 236);
  width:200px;
}
.form-textHead{
  font-size:13px;
  font-family:Montserrat,'sans-serif';
  font-weight:600;
  text-align:left !important;
 color:rgb(44, 43, 43);
 text-transform:uppercase;
 border-bottom:1px solid grey;
 
}

.badge{
  padding:10px 20px;
  background-color: green;
  border-radius:20px;
  box-shadow:0px 4px 6px 0px #8e8b8b;
}






.cell{
  padding:10px 10px;
  border-top:1px solid rgb(235, 235, 235);
  
  width:500px !important; 
  background-color:rgb(255, 255, 255);
  text-align: center;
}
.cell-head{
  padding:10px 10px;
 
  border-bottom:1px solid grey;
  width:auto; 
  background-color:rgb(255, 255, 255);

}
.busi-row{
  padding:10px;
  border-top:1px solid rgb(230, 230, 230);
}
.CardBody{
  border:1px solid rgb(236, 236, 236);
  
}

  .red{
      background-color: red;
      
  }
  .link{
      padding-top:20px;
      color:blue;
      }
  
  .link:hover{
  color:rgb(5, 5, 126);
  }

  a { color: inherit; } 

  a:hover {
      color: #ffffff;
      text-decoration: none;
  }

  a:active {
      color: #ffffff !important;
      text-decoration: none;
  }
  .header-div{
    
     
  padding:10px 30px;
background-color: white;
z-index:5000;}

.active1{
 border-top: 2px solid rgb(73, 133, 189) !important;
 border-left: 2px solid rgb(73, 133, 189) !important;
 border-right: 2px solid rgb(73, 133, 189) !important;
  font-weight:600px;
}


.header-div2{
    
 
padding:10px 30px;
background-color: white;
z-index:5000;}

.superoptions{
  border-top:2px solid rgb(197, 197, 197);
  border-left:2px solid rgb(197, 197, 197);
  border-right:2px solid rgb(197, 197, 197);
  text-align:center;
  padding:7px 0px;
  
  margin-left:15px;
  font-size:15px;
  font-family:montserrat,'sans-serif'
}

.superoptions:hover{
  cursor:pointer
}


.downdoc{
  padding:1px 0px;
}
.header-divi{
  height: 100px;
  padding: 10px 30px;
  background-color: #f6f6f6;
}

.header-divi1{
  height:120px;

padding:10px 30px;
background-color: white;}

.head-name
{font-weight:500;
  font-size:27px;
  text-align:left;
  padding-left:0px;
  font-family:montserrat,'sans-serif';
}

.head-mail{
  font-weight:400;
  font-size:14px;
  text-align:left;
  font-family:montserrat,'sans-serif';
}
.head-message{
  font-weight:500;
  font-size:25px;
  text-align:left;
  padding-left:14px;
  font-family:montserrat,'sans-serif';
}
.head-mail-important{
  font-weight:600;
  font-size:16px;
  text-align:left;
  font-family:raleway,'sans-serif';
}

.head-button{
  margin-top:20px;
  font-weight:400;
  font-size:14px;
  text-align:left;
}

.dedicated-page{
  margin-right:36px;
  overflow-x:hidden;
  height:620px;
  margin-left:-30px;
  background-color: white;
  box-shadow: 0px 1px 11px 0px #e9e6e6;
}

.dedicated1-page{
  margin-right:36px;
  overflow-x:hidden;
  height:620px;
  margin-left:-30px;
  border:1px solid rgb(233, 233, 233);
}
#section{
  
  overflow-y: scroll;
  height:700px;
  border:1px solid rgb(235, 235, 235);
  margin-right:20px;
}
.content-div{
  padding:2px 60px;
  
}

.content1-div{
  background-color: white;
  padding:30px 60px 20px 60px;
}
.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.Div{
 
  margin-right:40px;
  margin-left:-40px;
}

.list-head{
  color:white;
  font-family:raleway,'sans-serif';
  font-size:18px;
  font-weight:600;
  text-align:left;
  margin-bottom:40px;
  border-radius:4px;
  border-bottom: 1px solid #eeeeee;
  padding:10px 10px;
  margin-left:-15px;
  background-color:#1E5B81;
}
.section{
  margin-left:-8px;
 
}



.sidebar-link{
  color:rgb(255, 255, 255) !important;
  text-decoration: none !important;
  padding-bottom:5px;
  font-size:14px;

}
.sidebar-link:active{
  color:white !important;

}
.sidebar-link:hover{
  color:white;
  
}
.tablebusi{
  width:auto;
}
.dedicated-page2{
  padding:0px 20px;
  margin-right:36px;
  overflow-x:hidden;
  height:620px;
  margin-left:-30px;
  border:1px solid rgb(233, 233, 233);
  background-color: white;
}



li{
  text-decoration:none !important;
}

.tab{
 
  border-radius:10px;
  margin:10px 0px;
  padding:5px;
  width:140px;
  height:120px;
  background-color: rgb(200, 241, 253);
  box-shadow: 3px 2px 8px 0px #abd2ce
}
.tab:hover{
  
  border-radius:10px;
  margin:10px 0px;
  padding:5px;
  background-color: rgb(164, 231, 252);
  box-shadow: 3px 2px 8px 0px #abd2ce
}
.tab-row{
  padding:10px;
  border-top:5px solid rgb(248, 248, 248);
  border-bottom:5px solid rgb(248, 248, 248);
  margin-top:10px;
}
.tab-row1{
  padding:10px;

  margin-top:10px;
}
.tab-row-head{
font-size:15px;
color:rgb(68, 68, 68);
}

.Account-Card{
  margin:10px 120px;
  padding:20px;
  border:2px solid rgb(228, 228, 228);
  border-radius:10px;
  height:500px;
}
.account-head {
  color: rgb(53, 53, 53);
  font-family: raleway,'sans-serif';
  font-weight: 400;
  margin-top: 30px;
   font-size: 20px !important; 
   font-family: montserrat,'sans-serif';
  margin-bottom: 30px;
  /* margin-left: 30px; */
  background-color:#E5EEEF;
  padding: 10px 46px;
  font-weight:600;
}


.account-info{
  color:rgb(22, 31, 36);
  font-family: raleway,'sans-serif';
  
  font-weight:500;
  font-size:17px;
  padding:10px 0px;
}
.account-col1{

  padding-top:60px;

}
.account-col2{
 
  margin-top:0px;

}

.info-column{
   
}



.nav-item1{
  margin-right:10px;
 font-size:14px;
 font-family:raleway,'sans-serif';
 color:rgb(26, 26, 26) !important;
}
.nav-item:hover{
  color:black;
  
}

.nav-item:active{
  color:black;
 
}

.tableDiv {
  height: 70vh;
  overflow: auto;
  display: block;
}

.myTable{
  height: 74vh;
}

.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{

  border: none !important;
}

.ReactTable .rt-thead.-header{
  box-shadow: 0 0px 0px 0 rgba(0,0,0,0.15) !important;
}

.ReactTable{
  position: relative;
  display: flex;
  flex-direction: column;
  padding:20px 10px;
  background-color: white;
  border:none !important;
}

.fieldbox{
  box-shadow:none;
}
input[type=text], input[type=password] {
  background-color: #ffffff !important;
  color: #444444 !important;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 5px;
  border:1px solid rgb(209, 209, 209);
  width: 60%;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 5px 5px 5px 5px;
}

.content1-div > input[type=text], input[type=password] {
      background-color: #ffffff !important;
      color: #444444 !important;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 18px;
      margin: 5px;
      border:1px solid rgb(209, 209, 209);
      width: 98%;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      border-radius: 5px 5px 5px 5px;
  }

h4{
    
    
}
  textarea {
      background-color: #ffffff !important;
      color: #444444 !important;
      padding: 7px 10px;
      height: 122px;
      border: 1px solid #dadada;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 13px;
      margin: 4px 12px 0px 5px;
      /* margin: 1px 0px 0px 0px; */
     
      width: 85%;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      border-radius: 5px 5px 5px 5px;
  }
  input, button, select, textarea {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
  }

  .mainScreen{
      background-color:#F0F8FE;
      height:100vh;
  }
.button1{
  margin:30px;
  background-color: rgb(14, 184, 133);
  border: none;
  font-family:montserrat,'sans-serif';
  color: rgb(255, 255, 255);
  padding: 10px 32px;
  text-align: center;
  font-weight:500;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius:5px;
  cursor: pointer;
}


.buttonRed{

  background-color: rgb(255, 87, 9);
  border: none;
  font-family:montserrat,'sans-serif';
  color: rgb(255, 255, 255);
 border-radius:5px;
 font-family:montserrat,'sans-serif';
 padding:5px 20px;
 margin:0px 30px 
 
}
.buttonYellow{

  background-color: rgb(7, 164, 255);
  border: none;
  font-family:montserrat,'sans-serif';
  color: rgb(255, 255, 255);
 border-radius:5px;
 font-family:montserrat,'sans-serif';
 padding:5px 20px;
 margin:0px 30px 
 
}


/* FOR DASHBOARD CARDS */
.card-counter{
  box-shadow: 2px 2px 10px #DADADA;
  margin: 5px;
  padding: 20px 10px;
  background-color: #fff;
  height: 140px;
  border-radius: 5px;
  -webkit-transition: .3s linear all;
  transition: .3s linear all;
}

.card-counter:hover{
  box-shadow: 4px 4px 20px #DADADA;
  -webkit-transition: .3s linear all;
  transition: .3s linear all;
}

.card-counter.primary{
  background-color: rgb(224, 188, 27);
  color: #FFF;
}

.card-counter.primary1{
  background-color: rgb(8, 196, 243);
  color: #FFF;
}
.card-counter.danger{
  background-color: #ef5350;
  color: #FFF;
}  

.card-counter.success{
  background-color: #66bb6a;
  color: #FFF;
}  

.card-counter.info{
  background-color: #26c6da;
  color: #FFF;
}  

.card-counter i{
  font-size: 5em;
  opacity: 0.5;
}

.card-counter .count-numbers{
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 46px;
  display: block;
  font-weight:600;
}

.card-counter .count-name{
  position: absolute;
  right: 35px;
  top: 100px;
 color:white;
  text-transform: capitalize;
  opacity: 1;
  display: block;
  font-size: 18px;
  font-weight:600;
}

.fa-ticket{
    color:white;
}
/* END FOR DASHBOARD CARDS */

/*TIMELINE*/

.timeline {
  list-style-type: none;
  position: relative;
  z-index: 0;
}
.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 0;
}
.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
  z-index: 0;
}
.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #919191;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 0;
}

.update {
  list-style-type: none;
  position: relative;
  z-index: 0;
}
.update:before {
  content: ' ';
  background: #22c0e8;
  display: inline-block;
  position: absolute;
  left: 34px;
  width: 2px;
  height: 100%;
  z-index: 0;
}
.update > li {
  margin: 20px 0;
  padding-left: 20px;
  z-index: 0;
}
.update > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 30px;
  height: 30px;
  z-index: 0;
}

.proposals{
  list-style-type:none !important;
}

.float-right{
  float: right;
}

.float-left{
  float: left;
}

.attachment{
  padding-top:10px;
  background-color:rgb(255, 255, 255);
  border:1px solid rgb(196, 196, 196);
  font-size:15px;
  color:rgb(73, 73, 73);
}
#myFileInput {
  display:none;
}


.mail-head{
font-size:14px;
font-weight:500;

font-family:'montserrat', sans-serif;
}

.mail-subhead{
  font-size:14px;
  font-weight:400;
font-family:'montserrat', sans-serif;
}

.options-row {
  height: 60px;
  padding: 8px 40px;
  background-color: rgb(238, 238, 238);
   border-bottom: 1px solid #e7e7e7; 
}



.send-button{
  background-color: #1D5A81;
  width:200px;
  border:none;
  font-size:18px;
  margin-top:20px;
}


.fieldbox1{
  width:300px;
  height:30px;
}

.montserrat{
  font-family:montserrat,'sans-serif'
}

.timeline-head{
  font-size:18px;
  font-weight:600;
}

.timeline-subhead{
  font-size:14px;
  font-weight:400;
}

.timeline-card{
  border:2px solid #4EC1E8;
  padding:5px 20px;
  border-radius: 5px;
  width:800px;
  margin-left:30px;
}

.update-card{
  
  padding:5px 20px 30px 40px;
 
  
  width:100%;
}

.mail-card{
  border-left:1px solid  rgb(231, 231, 231);
  border-right:1px solid  rgb(233, 233, 233);
  border-bottom:1px solid  rgb(245, 245, 245);
  padding:10px 20px 10px 10px;
 
  background-color: white;
  width:100%;
}

.timeline-card > a:hover{
  color:black !important;
}

.timeline-elements:hover{
  color:black;
}

.mail-grid-button{
  background-color: #1D5A81;
  width:60px;
}

.notify-button{
  background-color: red;
  border-radius:50px;
 height:25px;
 margin-left:-15px;
 width:25px;
 font-size:13px;
 padding:0px;
  margin-bottom:30px;
  border:2px solid rgb(240, 125, 125);
}

.notify{
  padding-left:50px;
}

.bell-icon{
  height:25px;
}

.options{
  text-align:center;
  padding:8px 0px;
 
  color:white;
  font-size:16px;
  font-family:montserrat,'sans-serif'
}

.options2{
  width:146px;
  text-align:center;
  padding:8px 30px 8px 10px;
  margin-left:0px;
  
  font-size:14px;
 
  font-family:montserrat,'sans-serif'
}

.options5{
  width:135px;
  text-align:center;
  padding:8px 30px 8px 10px;
  margin-left:0px;
  
  font-size:14px;
 
  font-family:montserrat,'sans-serif'
}

.options8{
 
  text-align:center;
  padding:8px 10px 8px 10px;
  margin-left:0px;
  
  font-size:14px;
 
  font-family:montserrat,'sans-serif'
}

.options8{
 
cursor:pointer
}

.options6{
  width:185px;
  text-align:center;
  padding:8px 30px 8px 10px;
  margin-left:30px;
  
  font-size:14px;
 
  font-family:montserrat,'sans-serif'
}

.options7{
 
  text-align:center;
  padding:8px 0px 8px 10px;
  margin-left:0px;
  
  font-size:14px;
 
  font-family:montserrat,'sans-serif'
}
.options4{
  width:200px;
  text-align:center;
  padding:8px 30px 8px 10px;
  margin-left:0px;
  
  font-size:14px;
 
  font-family:montserrat,'sans-serif'
}

.active2{
  color:rgb(33, 108, 194) !important;
   font-weight:600 !important;
}
.options2:hover{
  cursor:pointer
}
.options3:hover{
  cursor:pointer
}
.options5:hover{
  cursor:pointer;
}
.options6:hover{
  cursor:pointer;
}
.options3 {
  width: 211px;
  text-align: center;
  padding: 8px 30px 8px 17px;
  margin-left: 21px;
  font-size: 14px;
  font-family: montserrat,'sans-serif';
}
.payStatus{
 background-color: transparent;
  border:none;
  font-size:15px;
}

.processStatus{
  background-color: transparent;
  border:none; 
  font-size:15px;
}

.head-button1{
  margin-top:20px;
  border-radius:6px;
  padding:6px;
  color:white;
  font-weight:600;
  background-color:#ED5353;
  border:none;
  box-shadow: 1px 2px 4px #adacac;
}

.head-button2{
  margin-top:6px;
  border-radius:6px;
  font-weight:600;
  padding:6px;
  color:white;
  background-color: #28b7f0;
  border:none;
  box-shadow: 1px 2px 4px #adacac;
  
}

.multidiv{
  margin-top:40px;
}

.options1{
  text-align:center;
  padding:7px 0px;
  margin:0px 20px;
  color:white;
  font-size:16px;
  font-family:montserrat,'sans-serif'
}
.options1:hover{
cursor:pointer;
}
.form-col{
  margin:10px 40px;
}

.document-card{
  margin:40px 30px;
  padding:20px;
  background-color:rgb(255, 255, 255);
  font-weight:700px;
  height:120px;
  font-size:17px;
  border-radius:10px;
  border:1px solid rgb(223, 223, 223);
}

.options{
  cursor:pointer;
}

.header{
  background-color:rgb(255, 255, 255);
  font-family: montserrat,'sans-serif';
  padding:20px 70px;
  font-size:32px;
  
  border-bottom:1px solid rgb(224, 224, 224);
  z-index:-5;
}
.card{
  background-color: white;
}

.alert-nouser{
  background-color:rgb(255, 255, 255);
  font-family: montserrat,'sans-serif';
  padding:20px 70px;
  font-size:32px;
  font-weight:700;
  color: rgb(192, 52, 52);
  border-bottom:1px solid rgb(224, 224, 224);
  z-index:-5;
}

.tab-main{
  color:white;
  border-radius:10px;
  margin:30px 0px;
  padding:5px;
  width:230px;
  height:180px;
  background-color:#48B5E4;
  box-shadow: 3px 2px 8px 0px #abd2ce
}

.tab-image{
  color:white !important;
  height:40px;
  margin-top:20px;
  font-size:50px;
}

.addUser{
  border:1px solid rgb(124, 124, 124);
  padding:5px 10px;
  color:rgb(46, 45, 45);
  border-radius:3px;
}
.regUser{
  
  padding:5px 10px;
  color:rgb(46, 45, 45);
  border-radius:3px;
  background-color:#F9C65C;
}

.tab-text-main{
  font-family:Montserrat,'sans-serif';
  font-weight:600;
  font-size:24px;
  color:white;
}
.header2{
  background-color:rgb(255, 255, 255);
  font-family: montserrat,'sans-serif';
  padding:20px 20px;
  font-size:24px;
  
  border-bottom:1px solid rgb(224, 224, 224);
  z-index:-5;
}


.header1{
  background-color:rgb(255, 255, 255);
  font-family: montserrat,'sans-serif';
  padding:20px 40px;
  font-size:24px;
  font-weight:700;
  border-bottom:1px solid rgb(224, 224, 224);
  z-index:10;
}
.doc-section{
  background-color: #ffffff;
  border:1px solid rgb(226, 226, 226);
  height:600px;
  overflow:scroll;
}

.table-head{
  font-size:14px;
  margin-left:60px;
  font-family: montserrat,'sans-serif';
 font-weight:600;

 padding:10px;
 background-color: white;
 text-align:center;
  width:140px;
}

.table-item {
  margin: 2px 10px;
  font-size: 14px;
  padding: 10px 15px;
  border: 1px solid rgb(198, 206, 207);
  text-align: center;
  background-color: #e8e9e930;
  font-family: montserrat,'sans-serif';
}
.doc-head-row{
  padding:10px 0px;
  border-bottom:1px solid rgb(230, 230, 230);
}

ul{
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.date-text{
font-size:15px;
font-weight:400;
font-family:Montserrat,'sans-serif';
}

.from-text{
  font-size:17px;
  font-weight:400;
  font-family:Montserrat,'sans-serif';
}
.message-text{
      font-size:16px;
      font-weight:400;
      font-family:Montserrat,'sans-serif';
      margin:30px 0px 0px 0px;
      }
.subject-text{
  margin:-30px 0px 0px -20px;
          font-size:24px;
          font-weight:600;
          font-family:Montserrat,'sans-serif';
          }

.RED-mail{
  border: 2px solid #e06363 !important;
}


.GREEN-mail{
  border: 2px solid #31ff35 !important;
}


.YELLOW-mail{
  border: 2px solid #ffcd16 !important;
}



.BLUE-mail{
  border: 2px solid #4EC1E8 !important;
}


.message-box{
  padding: 10px 30px !important;
  
  display: flex;
  width:430px;
  z-index:10;

}

.send-big-btn {
  margin: 0px 0px 0px 7px;
  width: 85px;
  border: none;
  height: 37px;
  font-size: 16px;
}

a:hover{
color:inherit !important;
}

.panel-buttons{
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  color: black;
  font-size: 15px;
  border:1px solid grey;
  margin-bottom: 10px;
  margin-top: 50px;
  font-family:Montserrat,'sans-serif';
}

.back-buttons{
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  color: black;
  font-size: 15px;
  border:1px solid grey;
  margin-bottom: 10px;
  margin-top: 0px;
  font-family:Montserrat,'sans-serif';
}

.butn{
  cursor:pointer;
}

.modal{
  z-index:10;
}
.cross-row{
  padding:4px;
  background-color: rgb(233, 233, 233)
}

.upload-box{
  font-size: 16px;
  margin-top: 2px;
  margin-top: 1px;
  border-radius: 3px;
  padding: 13px;
  background-color: white;
  padding: 7px;
  border: 1px solid #c5c5c5;
}
.chat-button {
  background-color: #fafafa;
  border-radius: 50px;
  height: 40px;
  border: none;
  width: 170px;
  color: #3f3f3f;
  font-weight: 600;
  font-size: 17px;
  margin-left: 70px;
  margin-top: 60px;
  box-shadow: 0px 0px 1px 1px #dedede;
}

.button-type{
  background-color: rgb(223, 223, 223);
  color:rgb(32, 32, 32);
  padding:7px 8px 0px 8px;
  border-radius:5px;
}

.masterMail-page{
  border-Top:1px solid rgb(245, 245, 245);
  border-Left:1px solid rgb(245, 245, 245);
  border-Right:1px solid rgb(245, 245, 245);
  height:590px;
  overflow-y:scroll;
}

a:hover{
  color:inherit !important;
}
a:active{
  color:inherit !important;
}

.form-label{

  margin:8px;
  font-size: 17px;
}

.select-form{
  background-color: #ffffff !important;
  color: #444444 !important;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 5px;
  border: 1px solid rgb(209, 209, 209);
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 5px 5px 5px 5px;
}

.control-label{
  font-size:14px;
  font-weight:500;
  font-family:montserrat,'sans-serif';
  margin-top:1px;
}

.form-group{
  padding:20px;
}
.add-header {
  font-weight: 600;
  margin-bottom: 50px;
  margin-top:40px;
  font-size: 18px;
  color: rgb(32, 32, 32);
  font-family: montserrat,'sans-serif';
  background-color: #e5eeef;
  padding: 10px 49px;
}






.add-customer-table{
  margin:10px;
}
.add-module-table{
  margin:0px 80px 0px 0px;
}


.add-button{
  background-color: #1E5B81;
  border-radius:50px;
  width:34px;
  color:white;
  padding:5px 5px 5px 2px;
}

.checkList{
  list-style-type:none;
}

.check-title{
  font-weight:600;
  font-size:17px;
  margin-left:10px;
  font-family: montserrat,'sans-serif'
}

.checkList-item{
  margin:10px 0px;
  background-color:#EAEAEA;
  width: 600px;
  padding:7px;
}

.progressLogs>li{
  border:2px solid rgb(233, 237, 238);
  margin:10px 10px;
  padding:10px;

}

.doc-head-row1{
  padding:15px;
}

.issueLogs{
   border:1px solid red;
}

.fieldbox2{
  width:98%;
}

.card-head{
font-size:22px;
}
.rt-td{
  border-top: 1px solid rgb(228, 228, 228);
  border-left: 1px solid rgb(228, 228, 228);
}



.box-container3{

  background: #ffffff00;
 
  
  
  }


.box-container2{

  background-color: #fbfbfc;
  height: calc(100vh -  90px);
  
  
  }
  

  

  .box-container3 img {
    width: 30vw;
    position: absolute;
    left: 25%;
    top: -4%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
      
     
   
           

     

           .box-container1 img {
            /* width: 50%; */
            position: absolute;
            /* left: 22%; */
            top: 5%;
            width: 34vw;
            -webkit-transition:all 0.3s ease-in-out;
            transition:all 0.3s ease-in-out;
           
        }
    

      .sub-logo{
        width: 45% !important;
        position:absolute;
       left: 27% !important;
       top:15% !important;
       -webkit-transition: all 0.4s ease-in-out;
       transition: all 0.4s ease-in-out;
      }
      .sub-logo-dashboard{
        width: 58% !important;
        position:absolute;
       left: 21% !important;
       top:15% !important;
       -webkit-transition: all 0.4s ease-in-out;
       transition: all 0.4s ease-in-out;
      }


      #subscribe{
        margin-top:4%;
      }

      #email{
        margin-top:4%;
      }

      .form-control {
        display: block;
        width:80%;
        height: calc(1.5em + .75rem + 2px);
        padding: .375rem .75rem;
        font-size: 17px;
        font-weight: 400;
        font-family: 'Montserrat';
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #f1f1f1;
        border-radius: .25rem;
        -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .email-div {
      font-weight: 500;
      font-size: 18px;
      margin: 7px -21px 0px 36px;
      padding: 0px;
      text-align: left;
      color: rgb(104, 104, 104);
      font-family: montserrat;
  }
      .email-div-f{
        color:rgb(255, 255, 255)
      }
   
    .sub-desc-div {
      font-family: montserrat,'sans-serif';
      margin-top: 45% !important;
  }
      .desc-div {
        font-family: montserrat,'sans-serif';
        margin-top: 1% !important;
        
        
    }
      .desc{
        font-size:20px;
        font-weight:500;
        color:#113855;
        text-align:center;
        
      }

      .sub-desc{
        font-size:24px;
        font-weight:700;
        color:#113855;
        text-align:center;
        
      }
      
      .navbar {
        background-color: #ffffff;
        height: 64px;
        z-index: 9999;
        width: auto;
        box-shadow: 5px 4px 9px #ebe9e947;
        margin-bottom: 0!important;
    }


      .favicon{
        height:15px;
      }


    .email-input-get-in-touch{
      width: 100%;

      
    }



      a {
        color: #464646;
        text-decoration: none;
        background-color: transparent;
    }

    
    @media screen and (min-width: 768px) and (max-width:1920px){

      .clipArt {
        height: 50vh;
        margin-top: 0%;
    }

    .mobile{
      display:none !important;
    }


    }
    

    @media screen and (max-width:768px){
      .website{
        display:none !important;
      }

      .email-div {
        font-weight: 500;
        font-size: 16px;
        margin: 10px -21px 5px 133px;
        padding: 0px;
        text-align: left;
        color: rgb(104, 104, 104);
        font-family: montserrat;
    }
    .sub-desc {
      font-size: 18px;
      font-weight: 700;
      margin: 0px 34px;
      color: #113855;
      text-align: center;
  }

  .sub-desc-div {
    font-family: montserrat,'sans-serif';
    margin-top: 69% !important;
}
      .desc {
        font-size: 15px;
        font-weight: 500;
        color: #113855;
        text-align: center;
    }
      .mobile{
        display:block !important;
      }
      .clipArt {
        height: 29vh;
        width: 60vw;
        margin-top: 5%;
    }

    .email-input-get-in-touch{
      width: 80%;
      margin:0px 28% 0px 11%;
    }



    .form-control {
      display: block;
      width:86% !important;
      height: calc(1.5em + .75rem + 2px);
      padding: .375rem .75rem;
      font-size: 15px !important;
      font-weight: 400;
      font-family: 'Montserrat';
      line-height: 1.5;
      color: #afafaf !important;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #f1f1f1;
      border-radius: .25rem;
      -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .box-container3 img {
    position: absolute;
    left: 24%;
    width: 55vw;
    top: -4%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}


    }

    .rodal-dialog {
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 101;
      padding: 15px;
      background: #60745e !important
      ;
      border-radius: 3px;
      box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
  }

.footer-item {
  color: #ffffff !important;
}


.footer-item {
  
  font-size: 16px;
  margin-left: -5px;
  font-family: 'Montserrat';
  font-weight: 600;
}

#footer-head {
  color: #ffffff !important;
  font-weight: 600;
  font-size: 17px;
  font-family: 'Montserrat';
}
@media (max-width: 768px){
  .footer{
    background-image:url(/static/media/footer.40551457.png);
    height:auto;
    margin-top:30px;
  }
 
#footer-head{
  margin-top:20px;
}}


ul {
  padding-left: 5px;
}



.email-input {
  height: 39px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.buttonsubs {
  color: rgb(0, 183, 255);
  background-color: #ffffff;
  margin-top: -20px;
  margin-bottom: 10px;
  border: none;
  padding: 12px 10px 10px 10px;
  text-align: center !important;
  display: inline-block;
  font-size: 15px;
  font-weight: 900;
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.buttonsubs:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}



.fa:hover{
	opacity:0.7;
}
/* Facebook */
.fa-facebook {
    background: #3162cc;
    color: white;
    font-size: 20px;
    height:30px;
    width: 30px;
    padding:10px;
    margin-top:-5px;
}
.fa-instagram {
  background: #ca136e;
  color: white;
  font-size: 20px;
  height:30px;
  width: 30px;
  padding:10px;
  margin-top:-5px;
}

.fa-youtube {
  background: #e92323;
  color: white;
  font-size: 20px;
  height:30px;
  width: 30px;
  padding:10px;
  margin-top:-5px;
}



.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #3a6b35;
  /* border-color: #007bff; */
}

.btn-primary {

 font-family:montserrat;
  border: none !important;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
}

.fa{
  
}
/* Twitter */
.fa-twitter {
    background: rgb(14, 109, 182);
    color: white;
    font-size: 20px;
    height:30px;
    width: 30px;
    padding:10px;
   }
   .fa-linkedin {
    background: rgb(1, 148, 158);
    color: white;
    font-size: 20px;
    height:30px;
    width: 30px;
    padding:10px;
    margin-top:10px;
}

.copytext{
  font-size:10px;
  color:#11428a;
}

.copyright {
  background-color: #afb75e;
  height: 42px;
  padding: 11px;
}

@media screen and (min-device-width: 768px) and (max-device-width:1920px){
  .footer {
    background-image:url(/static/media/footer.40551457.png);
    /* background-color: #042557; */
    margin-top:40px;
    background-size: cover;
    background-position-y: 92%;
    padding-top: 30px;
    height: 194px;
    position: inherit;
  }
  #footer-head {
    color: #ffffff !important;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Montserrat';
}
.footer-item {
  font-size: 14px;
  margin-left: -5px;
  font-family: 'Montserrat';
  font-weight: 600;
}
}

@media screen and (min-device-width: 481px) and (max-device-width:768px){

  .footer-item {
    font-size: 15px;
    margin-left: -5px;
    font-family: 'Montserrat';
    font-weight: 600;
  }
}
