
.footer-item {
  color: #ffffff !important;
}


.footer-item {
  
  font-size: 16px;
  margin-left: -5px;
  font-family: 'Montserrat';
  font-weight: 600;
}

#footer-head {
  color: #ffffff !important;
  font-weight: 600;
  font-size: 17px;
  font-family: 'Montserrat';
}
@media (max-width: 768px){
  .footer{
    background-image:url('../images/footer.png');
    height:auto;
    margin-top:30px;
  }
 
#footer-head{
  margin-top:20px;
}}


ul {
  padding-left: 5px;
}



.email-input {
  height: 39px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.buttonsubs {
  color: rgb(0, 183, 255);
  background-color: #ffffff;
  margin-top: -20px;
  margin-bottom: 10px;
  border: none;
  padding: 12px 10px 10px 10px;
  text-align: center !important;
  display: inline-block;
  font-size: 15px;
  font-weight: 900;
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  transition: all 0.2s ease-in;
}

.buttonsubs:hover {
  transform: scale(1.2);
}



.fa:hover{
	opacity:0.7;
}
/* Facebook */
.fa-facebook {
    background: #3162cc;
    color: white;
    font-size: 20px;
    height:30px;
    width: 30px;
    padding:10px;
    margin-top:-5px;
}
.fa-instagram {
  background: #ca136e;
  color: white;
  font-size: 20px;
  height:30px;
  width: 30px;
  padding:10px;
  margin-top:-5px;
}

.fa-youtube {
  background: #e92323;
  color: white;
  font-size: 20px;
  height:30px;
  width: 30px;
  padding:10px;
  margin-top:-5px;
}



.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #3a6b35;
  /* border-color: #007bff; */
}

.btn-primary {

 font-family:montserrat;
  border: none !important;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
}

.fa{
  
}
/* Twitter */
.fa-twitter {
    background: rgb(14, 109, 182);
    color: white;
    font-size: 20px;
    height:30px;
    width: 30px;
    padding:10px;
   }
   .fa-linkedin {
    background: rgb(1, 148, 158);
    color: white;
    font-size: 20px;
    height:30px;
    width: 30px;
    padding:10px;
    margin-top:10px;
}

.copytext{
  font-size:10px;
  color:#11428a;
}

.copyright {
  background-color: #afb75e;
  height: 42px;
  padding: 11px;
}

@media screen and (min-device-width: 768px) and (max-device-width:1920px){
  .footer {
    background-image:url('../images/footer.png');
    /* background-color: #042557; */
    margin-top:40px;
    background-size: cover;
    background-position-y: 92%;
    padding-top: 30px;
    height: 194px;
    position: inherit;
  }
  #footer-head {
    color: #ffffff !important;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Montserrat';
}
.footer-item {
  font-size: 14px;
  margin-left: -5px;
  font-family: 'Montserrat';
  font-weight: 600;
}
}

@media screen and (min-device-width: 481px) and (max-device-width:768px){

  .footer-item {
    font-size: 15px;
    margin-left: -5px;
    font-family: 'Montserrat';
    font-weight: 600;
  }
}